import { PillButton } from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingContainer from '../OnboardingContainer'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import InsuranceProviderSelect from 'components/Select/InsuranceProviderSelect'
import useEligibilityInfo from 'hooks/useUser/useEligibilityInfo'
import { WaitlistUsersDto } from '../../../hooks/useUser/useEligibilityInfo'
import produce from 'immer'
import withEligibility, { WithEligibilityProps } from 'hoc/withEligibility'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import Loader from 'components/Loader'
import { RoutePaths } from '../../Core/Routes'
import './styles.scss'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from 'utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'

const InsuranceEligibility: React.FC<WithEligibilityProps> = ({
  waitlistUser,
}) => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const { trackPageView } = UseOnboardingPageView()
  const { handleUpdateWaitlistUser } = useEligibilityInfo()
  const [loading, setLoading] = useState<boolean>(false)
  const [showProviderInput, setShowProviderInput] = useState(false)

  const [selectedInsuranceProvider, setSelectedInsuranceProvider] = useState<
    string | undefined
  >(undefined)

  const handleInsuranceProviderChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedInsuranceProvider(e.target.value)
    },
    [setSelectedInsuranceProvider]
  )

  const handlePlanTypeSelection = (isNonCommercePlan: boolean) => {
    if (isNonCommercePlan) {
      Amplitude.insurancePlanEligibility('yes')
      onContinue('NONCOMMERCE')
    } else {
      Amplitude.insurancePlanEligibility('no')
      setShowProviderInput(true)
    }
  }

  const onContinue = useCallback(
    (planType: 'NONCOMMERCE' | 'COMMERCE') => {
      setLoading(true)
      const waitlistUserDto: WaitlistUsersDto = { ...waitlistUser }
      const nextWaitlistUser = produce(waitlistUserDto, (draftWaitlistUser) => {
        draftWaitlistUser.insurancePlan = planType

        if (planType === 'COMMERCE') {
          draftWaitlistUser.insuranceProvider = selectedInsuranceProvider
          draftWaitlistUser.insuranceStateCode = waitlistUser.state.code
        }
      })

      if (selectedInsuranceProvider) {
        Amplitude.insuranceEligibility(
          waitlistUser.state.code,
          selectedInsuranceProvider
        )
      }

      handleUpdateWaitlistUser(nextWaitlistUser)
        .then((res) => {
          setLoading(false)

          if (res) {
            const { eligibleProducts, insurancePlan } = res.data

            const isInsuranceEligible = eligibleProducts.find(
              (prod) =>
                prod.type === PROGRAMS.INSURANCE ||
                prod.type === PROGRAMS.INSURANCE_DIAGNOSTIC
            )

            let path = RoutePaths.INSURANCE_NOT_COVERED

            if (isInsuranceEligible !== undefined) {
              path =
                insurancePlan !== 'NONCOMMERCE'
                  ? RoutePaths.INSURANCE_COVERED
                  : RoutePaths.INSURANCE_NOT_COVERED
            }

            history.push(`${path}${window.location.search}`)
          }
        })
        .catch(() => {
          setLoading(false)
          setAlertText('Error checking for eligibility', 'An Error Occurred')
        })
    },
    [selectedInsuranceProvider, waitlistUser]
  )

  // Track page view
  useEffect(() => {
    trackPageView('insuranceEligibility')
  }, [])

  return (
    <OnboardingContainer>
      <div className="d-flex justify-content-center">
        <div className="insurance-elegibility mt-5 py-3 px-3 m-4 d-flex flex-column">
          {!showProviderInput ? (
            <>
              <p className="mb-2 mt-4 ff-inter-medium fs-4">
                Do you have one of the following plan types?
              </p>
              <p>
                <ul>
                  <li>Medicaid</li>
                  <li>Medicare</li>
                  <li>Tricare</li>
                  <li>HMO</li>
                </ul>
              </p>
              <div className="justify-content-center d-flex flex-column align-items-center">
                <PillButton
                  className="justify-content-center mt-2 mb-2 me-3 w-75 ff-inter-medium pill-button-primary"
                  onClick={() => handlePlanTypeSelection(false)}
                  size="sm"
                >
                  No
                </PillButton>
                <PillButton
                  className="justify-content-center mt-2 w-75 ff-inter-medium pill-button-secondary "
                  onClick={() => handlePlanTypeSelection(true)}
                  size="sm"
                >
                  Yes
                </PillButton>
              </div>
            </>
          ) : (
            <>
              <p className="mb-2 mt-4 ff-inter-medium fs-4">
                Select your insurance provider:
              </p>
              <div className="w-100">
                <InsuranceProviderSelect
                  onChange={handleInsuranceProviderChange}
                  value={selectedInsuranceProvider}
                />
              </div>
              <PillButton
                className="justify-content-between mt-3 w-100 ff-inter-medium"
                onClick={() => onContinue('COMMERCE')}
                disabled={!selectedInsuranceProvider}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <div>Continue</div>
                    <RightIcon />
                  </>
                )}
              </PillButton>
            </>
          )}
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default withEligibility(InsuranceEligibility)
