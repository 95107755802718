import { logEvent } from '@amplitude/analytics-browser'

export class Amplitude {
  static userHasInsurance(cta: 'yes' | 'no') {
    logEvent('User Has Insurance', {
      cta,
    })
  }

  static insuranceEligibility(state_field: string, insurance_company: string) {
    logEvent('Insurance Eligibility', {
      state_field,
      insurance_company,
    })
  }

  static insurancePlanEligibility(cta: 'yes' | 'no') {
    logEvent('User has non-commerce insurance', {
      cta,
    })
  }

  static insuranceCoverageLoaded(
    coverage: 'covered' | 'testing-covered' | 'more-info'
  ) {
    logEvent('Insurance coverage Loaded', {
      coverage,
    })
  }

  static insuranceCoverage(cta: 'covered' | 'testing-covered') {
    logEvent('Insurance coverage', {
      cta,
    })
  }

  static userSelectsProduct(cta: string) {
    logEvent('User Selects Product', {
      cta,
    })
  }

  static userViewsPaymentPlans() {
    logEvent('User Views Payment Plans')
  }

  static initiatesCheckout(cta: 'checkout' | 'consult', product_tier?: string) {
    logEvent(
      'Initiates Checkout',
      product_tier
        ? {
            product_tier,
            cta,
          }
        : { cta }
    )
  }

  static userEntersPayment(time_on_page_in_seconds: number) {
    logEvent('User Enters Payment', {
      time_on_page_in_seconds,
    })
  }

  static howDidYouHear(choices: string[], cta: 'share' | 'skip') {
    logEvent('How Did You Hear', {
      choices,
      cta,
    })
  }

  static consentToTelehealth() {
    logEvent('Consent To Telehealth')
  }

  static insuranceDetails(time_on_page_in_seconds: number) {
    logEvent('Insurance Details', {
      cta: 'submit',
      time_on_page_in_seconds,
    })
  }

  static bookAppointment() {
    logEvent('Book Appointment')
  }
}
