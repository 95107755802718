import React, { useCallback } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useStytch } from '@stytch/react'
import AppLogo from 'components/AppLogo'
import useUser from 'hooks/useUser/useUser'
import { StytchAPIError } from '@stytch/vanilla-js'

interface FormData {
  email: string
  password: string
}

const Login: React.FC = () => {
  const redirectUri =
    window.location.origin +
    (window.location.pathname !== '/login'
      ? window.location.pathname
      : RoutePaths.ONBOARDING) +
    window.location.search
  const stytchClient = useStytch()
  const toast = useToast()
  const { postLoginExecution } = useUser()

  const initialValues: FormData = {
    email: '',
    password: '',
  }

  const authenticatePassword = useCallback(
    async (values: FormData) => {
      try {
        const response = await stytchClient.passwords.authenticate({
          email: values.email,
          password: values.password,
          session_duration_minutes: 60,
        })

        if (response.status_code === 200) {
          postLoginExecution()
          window.location.href = redirectUri
        }
      } catch (error) {
        let title = 'Invalid email or password'
        let description = ''

        if (error instanceof StytchAPIError) {
          if (error.error_type === 'reset_password') {
            title = 'Reset your password'
            description = `Please reset your password by clicking Forgot password.`
          } else {
            title = 'Error signing in'
            description = `Incorrect email or password.`
          }
        }

        toast({
          title: title,
          description: description,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    [stytchClient]
  )

  const resetPasswordStart = useCallback(
    async (email: string) => {
      if (!email) {
        toast({
          title: 'Please enter an email address',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        return
      }

      stytchClient.passwords.resetByEmailStart({
        reset_password_template_id: 'password_reset',
        email: email,
      })
      toast({
        title:
          'If an account exists with that email address, a password reset link will be sent.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    },
    [stytchClient, toast]
  )

  return (
    <ChakraProvider>
      <Flex
        justify="center"
        align="center"
        height="100vh"
        backgroundColor="var(--gray-extra-light)"
      >
        <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="sm">
          <Center mb={50}>
            <AppLogo color={'var(--blue-bright)'} />
          </Center>
          <Center>
            <Text color="gray.700" as="h5" size="m" alignContent={'center'}>
              Welcome back!
            </Text>
          </Center>
          <Formik initialValues={initialValues} onSubmit={authenticatePassword}>
            {({ values }) => (
              <Form>
                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Field as={Input} id="email" name="email" type="email" />
                  <ErrorMessage name="email" component={FormErrorMessage} />
                </FormControl>

                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Field
                    as={Input}
                    id="password"
                    name="password"
                    type="password"
                  />
                </FormControl>

                <Button
                  variant="link"
                  onClick={() => {
                    resetPasswordStart(values.email)
                  }}
                  size={'sm'}
                >
                  {' '}
                  Forgot password?
                </Button>

                <Button
                  type="submit"
                  backgroundColor={'var(--blue-light)'}
                  color="white"
                  width="full"
                  mt={6}
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                  }}
                >
                  Sign in
                </Button>
              </Form>
            )}
          </Formik>
          <Flex direction="column" align="center" mt={8}>
            <div>Don&apos;t have an account?</div>
            <Button
              variant="link"
              onClick={() =>
                (window.location.href =
                  window.location.origin + RoutePaths.CHECK_ELIGIBILITY)
              }
            >
              See if you&apos;re eligible
            </Button>
          </Flex>
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default Login
