import { useCallback, useMemo, useState } from 'react'
import {
  CardElement,
  CardElementProps,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  PaymentMethodResult,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js'
import { Col, Form, Row } from 'react-bootstrap'
import { Formik, FormikHelpers } from 'formik'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import { PillButton } from 'components/Button'
import Loader from 'components/Loader'
import { paymentFormNoTNCSchema, paymentFormSchema } from './utils'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import {
  PromoCode,
  usePromoCodeContext,
} from 'context/PromoCodeContext/PromoCodeProvider'
import usePayment from 'hooks/usePayment/usePayment'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import CheckoutDetails from './CheckoutDetails'
import { Amplitude } from 'utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'
import Address from 'components/Address'
import {
  ChakraProvider,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react'
import { FormInputs } from 'components/Form'

const rowProps = {
  className: 'py-1',
}

export interface PaymentFormValues {
  firstName: string
  lastName: string
  addresses: [
    {
      address_line1: string
      address_line2: string
      city: string
      state: string
      zip: string
    }
  ]
  paymentInformationConsent: boolean
}

const initialValues: PaymentFormValues = {
  firstName: '',
  lastName: '',
  addresses: [
    {
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      zip: '',
    },
  ],
  paymentInformationConsent: false,
}

interface CheckoutFormProps {
  isDirectFlow?: boolean
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ isDirectFlow }) => {
  const stripe = useStripe()
  const history = useHistory()
  const elements = useElements()
  const { setAlertText } = useAlert()
  const [error, setError] = useState<StripeError>()
  const [cardComplete, setCardComplete] = useState(false)
  const { promoCode } = usePromoCodeContext()
  const {
    selectedPlan,
    markPaymentComplete,
    selectedProgram,
  } = useOnboardingContext()
  // eslint-disable-next-line
  const {
    createPaymentIntent,
    processCardPayment,
    attachPaymentMethod,
  } = usePayment()

  const cardElementOptions: CardElementProps['options'] = {
    style: {
      base: {
        fontSize: '16px',
        fontWeight: 300,
      },
    },
    hidePostalCode: true,
  }

  const onCardChange = (e: StripeCardElementChangeEvent) => {
    setError(e.error)
    setCardComplete(e.complete)
  }

  const onSuccess = useCallback(async (promoCode?: PromoCode) => {
    markPaymentComplete()

    return history.push(
      `${RoutePaths.PAYMENT_COMPLETE}${promoCode ? '?p=1' : ''}`
    )
  }, [])

  const startTime = useMemo(() => {
    return Date.now()
  }, [])

  const checkBoxText = useMemo(() => {
    if (
      selectedProgram === PROGRAMS.INSURANCE ||
      selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC
    ) {
      return `By checking this box you acknowledge that your payment information will be kept on file for outstanding charges such as copays or coinsurance${
        selectedProgram === PROGRAMS.INSURANCE
          ? ' and for subscription auto-renewal.'
          : '.'
      }`
    } else {
      return `By checking this box you acknowledge that your payment information will be kept on file for outstanding charges${
        selectedProgram !== PROGRAMS.MNC
          ? ' and for subscription auto-renewal.'
          : '.'
      }`
    }
  }, [selectedProgram])

  const onSubmitHandler = async (
    { firstName, lastName, addresses }: PaymentFormValues,
    { setSubmitting }: FormikHelpers<PaymentFormValues>
  ) => {
    if (!selectedPlan) {
      setSubmitting(false)
      return setAlertText(
        `You have not selected a plan yet. Please return to ${
          process.env.REACT_APP_URL
        }/${
          isDirectFlow
            ? RoutePaths.DIRECT_PAYMENT_PLANS
            : RoutePaths.PAYMENT_PLANS
        }${window.location.search}`,
        'Hang on a second'
      )
    }

    // Temporarily hardcoding for "United States"
    const countryCode = 'US'

    const { error, paymentMethod } = (await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardElement) as StripeCardElement,
      billing_details: {
        name: firstName + ' ' + lastName,
        address: {
          line1: addresses[0].address_line1,
          city: addresses[0].city,
          postal_code: addresses[0].zip,
          state: addresses[0].state,
          country: countryCode,
        },
      },
    })) as PaymentMethodResult

    if (error) {
      setError(error)
      setSubmitting(false)
      return
    }

    if (!paymentMethod) {
      return setAlertText(
        'Card could not be verified. Please try again.',
        'An Error Occurred'
      )
    }

    Amplitude.userEntersPayment(Math.round((Date.now() - startTime) / 1000))

    const paymentIntentResponse = await createPaymentIntent(
      selectedPlan,
      promoCode?.code,
      isDirectFlow
    ).catch(({ error }) => {
      setError(error)
      setSubmitting(false)
      setAlertText(error, 'An Error Occurred')
      return null
    })

    if (paymentIntentResponse === null) {
      return
    }

    const { client_secret } = paymentIntentResponse

    if (client_secret) {
      // Attach and process
      try {
        await attachPaymentMethod(paymentMethod.id)
      } catch (err) {
        console.error('Unable to attach payment method')
        console.error(err)
      } finally {
        try {
          const isSuccess = await processCardPayment(
            client_secret,
            paymentMethod.id
          )
            .then(() => true)
            .catch((error) => {
              setAlertText(error.error, 'An Error Occurred')
              throw error.error
            })

          if (isSuccess) {
            onSuccess(promoCode)
          }
        } catch {
          setSubmitting(false)
        }
      }
    } else {
      // No payment to process so just attach payment method
      await attachPaymentMethod(paymentMethod.id).catch(() =>
        setAlertText(
          'Trial enabled but could not process credit card. Please update in the profile tab of your dashboard before billing begins.',
          'Warning'
        )
      )
      onSuccess(promoCode)
    }
  }

  return (
    <ChakraProvider>
      <Formik
        initialValues={initialValues}
        validationSchema={
          selectedProgram !== PROGRAMS.MNC
            ? paymentFormSchema
            : paymentFormNoTNCSchema
        }
        onSubmit={onSubmitHandler}
      >
        {({ values, errors, isSubmitting, handleSubmit, handleChange }) => {
          const {
            firstName,
            lastName,
            addresses,
            paymentInformationConsent,
          } = values

          return (
            <div className="d-flex justify-content-center align-items-center">
              <form
                onSubmit={handleSubmit}
                className="checkout-form d-flex justify-content-center"
              >
                <div className="checkout-form-sub checkout-form-sub-1">
                  <Heading as="h4" size="l" color={'black'} mb={6} mt={6}>
                    Payment Details
                  </Heading>
                  <Row {...rowProps}>
                    {error?.message && (
                      <p className="text-danger text-center">
                        {error?.message}
                      </p>
                    )}
                  </Row>
                  <Grid
                    templateColumns={{ base: '1fr 1fr', md: '1fr 1fr' }}
                    alignSelf="stretch"
                    gap={6}
                    color={'black'}
                  >
                    <GridItem>
                      <FormInputs
                        htmlFor="firstName"
                        label="First Name"
                        inputtype="text"
                        id="firstName"
                        value={firstName}
                        errors={errors.firstName}
                      />
                    </GridItem>
                    <GridItem>
                      <FormInputs
                        htmlFor="lastName"
                        label="Last Name"
                        inputtype="text"
                        id="lastName"
                        value={lastName}
                        errors={errors.lastName}
                      />
                    </GridItem>
                  </Grid>
                  <Grid
                    templateColumns={{ base: '1fr', md: '1fr' }}
                    alignSelf="stretch"
                    mt={6}
                    mb={6}
                  >
                    <GridItem>
                      <FormLabel fontWeight={300} color={'var(--gray-dark)'}>
                        Card Information
                      </FormLabel>
                      <div id="card" className="card-element-container">
                        <CardElement
                          options={cardElementOptions}
                          onChange={onCardChange}
                        />
                      </div>
                      <FormErrorMessage>{error?.message}</FormErrorMessage>
                    </GridItem>
                  </Grid>
                  <Grid
                    templateColumns={{ base: '2fr 1fr', md: '2fr 1fr' }}
                    alignSelf="stretch"
                    gap={6}
                    mb={6}
                    color={'black'}
                  >
                    <Address billing={true} />
                  </Grid>
                </div>
                <div className="checkout-form-sub checkout-form-sub-2">
                  <Heading as="h5" size="l" color={'black'} mb={6} mt={6}>
                    Summary
                  </Heading>
                  <CheckoutDetails />
                  <Form.Group
                    className="tnc-container d-flex align-items-start"
                    controlId="paymentInformationConsent"
                  >
                    <Form.Check
                      checked={paymentInformationConsent}
                      onChange={handleChange}
                    />
                    <Form.Label className="fs-3 mx-3 text-transform-none">
                      {checkBoxText}
                    </Form.Label>
                  </Form.Group>
                  <Row {...rowProps} className="py-3">
                    <Col className="d-flex justify-content-center">
                      <PillButton
                        className="checkout-button position-relative justify-content-between w-100"
                        type="submit"
                        block
                        disabled={
                          isSubmitting ||
                          !(
                            cardComplete &&
                            stripe &&
                            firstName &&
                            addresses[0].address_line1 &&
                            addresses[0].city &&
                            addresses[0].state &&
                            addresses[0].zip &&
                            lastName &&
                            paymentInformationConsent
                          )
                        }
                      >
                        {isSubmitting ? (
                          <Loader />
                        ) : (
                          <>
                            <div>Submit</div>
                            <div className="continue-button">
                              <RightIcon />
                            </div>
                          </>
                        )}
                      </PillButton>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col className="d-flex justify-content-center">
                      {promoCode?.discount?.subtitle && (
                        <p>*{promoCode.discount.subtitle}</p>
                      )}
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
          )
        }}
      </Formik>
    </ChakraProvider>
  )
}

export default CheckoutForm
