import { WithAccountProps } from 'hoc/withAccount'
import { Col, Container, Row } from 'react-bootstrap'
import Appointments from './Appointments'
import ComingUp from './ComingUp'
import Completed from './Completed'
import Timeline from './Timeline'
import { memo } from 'react'
import withAccountStatus from 'hoc/withAccountStatus'

import './styles.scss'

const Dashboard: React.FC<WithAccountProps> = (props) => {
  return (
    <Container fluid className={`px-0 mt-lg-2 dashboard`}>
      <Row className="h-100">
        <Timeline {...props} />

        <Col sm={12} xl={6} className="dashboard-col-l">
          <ComingUp {...props} />
          <Completed {...props} />
        </Col>
        <Col sm={12} xl={6}>
          <Appointments />
        </Col>
      </Row>
    </Container>
  )
}

export default withAccountStatus(memo(Dashboard))
