import { useStytch, useStytchSession } from '@stytch/react'
import {
  Appointments as AppointmentsRoute,
  QueryKey as AppointmentsQueryKey,
  AppointmentChoiceEligibility as AppointmentChoiceEligibilityRoute,
  AppointmentChoiceEligibilityQueryKey,
} from 'api/appointments/routes'
import { Provider } from 'hooks/useUser/useUser'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export type Appointment = {
  id: string
  sourceId: string
  user: string
  provider: Provider
  time: string
  isCancelled?: boolean
  videoCallLink?: string
}

export type AppointmentDefinition = {
  id: string
  title: string
  description: string
  icon: string // URL
  buttonText?: string
}

interface UseAppointmentsInterface {
  isLoading: boolean
  appointments: Appointment[]
  hasBookedAtleastTwoMd: boolean
  hasBookedRd: boolean
  isEligibleToChooseAppointmentType: boolean
  isAppointmentChoiceEligibilityLoading: boolean
  hasBookedNumberRDAppointments: (numAppointments: number) => boolean
  hasBookedNumberMDAppointments: (numAppointments: number) => boolean
}

const useAppointments = (): UseAppointmentsInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchAppointments = async (): Promise<Appointment[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${AppointmentsRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, data } = useQuery(
    [AppointmentsQueryKey, 'appointments'],
    fetchAppointments
  )

  const fetchAppointmentChoiceEligibility = async () => {
    if (!session) {
      return false
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentChoiceEligibilityRoute}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return false
        }
      })
      .catch(() => {
        return false
      })
  }

  const {
    data: isEligibleToChooseAppointmentType,
    isLoading: isAppointmentChoiceEligibilityLoading,
  } = useQuery(
    AppointmentChoiceEligibilityQueryKey,
    fetchAppointmentChoiceEligibility
  )

  const appointments = useMemo(() => data ?? [], [data])
  const hasBookedAtleastTwoMd = useMemo(
    () =>
      appointments.filter(
        (a) => a.provider.type === 'Medical Provider' && !a.isCancelled
      ).length > 1,
    [appointments]
  )
  const hasBookedNumberMDAppointments = (numAppointments: number) =>
    appointments.filter(
      (a) => a.provider.type === 'Medical Provider' && !a.isCancelled
    ).length > numAppointments
  const hasBookedNumberRDAppointments = (numAppointments: number) =>
    appointments.filter(
      (a) => a.provider.type === 'Dietician' && !a.isCancelled
    ).length > numAppointments
  const hasBookedRd = useMemo(
    () =>
      appointments.filter(
        (a) => a.provider.type === 'Dietician' && !a.isCancelled
      ).length > 0,
    [appointments]
  )

  return {
    isLoading,
    appointments,
    hasBookedRd,
    hasBookedAtleastTwoMd,
    isEligibleToChooseAppointmentType,
    isAppointmentChoiceEligibilityLoading,
    hasBookedNumberRDAppointments,
    hasBookedNumberMDAppointments,
  }
}

export default useAppointments
